var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recharge-success df fdc alc" },
    [
      _c("icon-svg", {
        staticClass: "main-color success-svg",
        attrs: { "icon-class": "yi_icon_xuanze" },
      }),
      _c("div", [_vm._v("¥" + _vm._s(_vm.money) + "充值成功")]),
      _c(
        "div",
        { staticClass: "btn-box df fdr alc jcc" },
        [
          _c(
            "router-link",
            {
              staticClass: "setting-btn",
              attrs: { to: "account", replace: "" },
            },
            [_vm._v("返回我的账户")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }