<template>
  <div class="recharge-success df fdc alc">
    <icon-svg class="main-color success-svg" icon-class="yi_icon_xuanze"/>
    <div>¥{{money}}充值成功</div>
    <div class="btn-box df fdr alc jcc">
      <router-link to="account" class="setting-btn" replace>返回我的账户</router-link>
    </div>
  </div>
</template>

<script>
import {wxShare}  from "@/utils/common";
import {baseUrl} from "@/utils/env";

export default {
  name: "success",
  data() {
    return {
      money: '',
    }
  },
  methods: {
    getParams() {
      this.money = this.$route.query.money ? this.$route.query.money : '';
    },
  },
  //路由改变jssdk参数重新赋值
  beforeRouteEnter(to, from, next) {
    next();
    wxShare();
  },
  mounted() {
    this.getParams();
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.recharge-success {
  .wh(100%, 100vh);
  background: @color-white;;
  padding: 0.7rem 0.74rem;
  .sc(0.36rem, #333);

  .success-svg {
    width: 2.2rem !important;
    height: 2.2rem !important;
    margin-bottom: 0.3rem;
  }

  .btn-box {
    margin-top: 0.64rem;
    width: 100%;

    .detail-btn, .setting-btn {
      .wh(2.62rem, 0.68rem);
      line-height: 0.68rem;
      border-radius: 0.44rem;
      text-align: center;
    }

    .detail-btn {
      .sc(0.32rem, var(--main-color));
      border: 0.02rem solid var(--main-color);
    }

    .setting-btn {
      .sc(0.32rem, @color-grey);
      border: 0.02rem solid @color-grey;


    }
  }
}

</style>